
















import useGlobalStorage from "@/composables/useGlobalStorage";
import { defineComponent, PropType, reactive, watch } from "@vue/composition-api";
import { toastController } from "@/composables/useToast";
import { useChargerAPI } from "@/api-connectors/useChargerAPI";
import FormCharger from "@/components/Modal/Subcomponents/FormCharger.vue";
import { Charger } from "@/types/apiEntities";
import { FormChargerType } from "@/types/RequestTypes";
import { modalController } from "@/composables/useModal";
import { emptyObjects } from "@/types/emptyObjects";

export default defineComponent({
    name: "EditChargingStation",
    components: { FormCharger },
    props: {
        // unassignedStations: {
        //     type: Array as () => Array<{ value: string, text: string }>,
        //     required: true
        // },
        selectedCharger: {
            type: Object as PropType<FormChargerType>,
            required: true
        }
    },
    setup(props, { emit }) {
        const { globalStore } = useGlobalStorage()
        const { editChargingStationRequest } = useChargerAPI()
        const { emptyCharger } = emptyObjects()

        const chargingStation = reactive<FormChargerType>(props.selectedCharger)

        const resetValues = () => {
            Object.assign(chargingStation, emptyCharger())
        }

        const editChargingStation = async () => {
            const response = await editChargingStationRequest(chargingStation.id ?? '', chargingStation)

            resetValues()

            if (!response) return;

            toastController.toast(`Die Ladestation wurde zugeordnet`, {
                title: 'Ladestation zugeordnet',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            emit('update-table')
        }

        const updateChargingStation = (updatedStation: Charger) => {
            Object.assign(chargingStation, updatedStation)
        }

        const closeModal = () => {
            resetValues()
            modalController.hide('edit-charging-station')
        }

        const setSelectedCharger = () => {
            Object.assign(chargingStation, props.selectedCharger)
        }

        watch(props.selectedCharger, () => setSelectedCharger())

        return {
            chargingStation,
            globalStore,
            editChargingStation,
            updateChargingStation,
            closeModal
        }
    }
})
