var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('searchbar-table',{on:{"update":function($event){_vm.filter = $event; _vm.updateChargingStationTable()}}}),_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"custom-table",attrs:{"items":_vm.items,"per-page":_vm.perPage,"fields":_vm.fields,"busy":_vm.isBusy,"table-class":"no-opacity-busy edit-on-row-click","thead-class":"th-custom","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sticky-header":"","borderless":"","hover":"","fixed":"","show-empty":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"row-clicked":function (item) { return _vm.showEditChargingStation(item); }},scopedSlots:_vm._u([{key:"head(id)",fn:function(){return [_c('b-btn',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.showAssignChargingStation}},[_c('b-icon',{attrs:{"icon":"plus-circle"}})],1)]},proxy:true},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-truncate",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])]}},{key:"cell(address)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.street)+" "+_vm._s(item.number)),_c('br'),_vm._v(" "+_vm._s(item.zipCode)+" "+_vm._s(item.city)+" ")])]}},{key:"cell(edit)",fn:function(ref){
var item = ref.item;
return [_c('b-btn',{attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.showEditWhiteList(item)}}},[_vm._v(" Verwalten "),_c('b-icon',{attrs:{"icon":"pencil-square"}})],1)]}},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('b-btn',{attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.checkRemoveChargingStation(item.id)}}},[_vm._v(" Löschen "),_c('b-icon',{attrs:{"icon":"x-circle"}})],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-info my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Lädt...")])],1)]},proxy:true}])})],1)],1),_c('pagination-row',{attrs:{"current-page":_vm.currentPage,"amount-rows":_vm.amountRows,"per-page":_vm.perPage,"row-class":"pagination-row"},on:{"update-page":function($event){_vm.currentPage = $event; _vm.updateChargingStationTable($event)},"update-page-max":function($event){_vm.perPage = $event; _vm.updateChargingStationTable(1)}}}),_c('add-charging-station',{attrs:{"unassigned-stations":_vm.unassignedStations},on:{"update-table":function($event){return _vm.updateChargingStationTable()}}}),_c('edit-charging-station',{attrs:{"selected-charger":_vm.selectedCharger},on:{"update-table":function($event){return _vm.updateChargingStationTable()}}}),_c('edit-whitelist-charging-station',{attrs:{"selectedCharger":_vm.selectedCharger,"options":_vm.optionsChargerPointWhitelist},on:{"update-whitelist":function($event){return _vm.updateChargingPointWhitelist($event)},"update":function($event){return _vm.editWhitelist($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }