















import useGlobalStorage from "@/composables/useGlobalStorage";
import { defineComponent, reactive } from "@vue/composition-api";
import router from "@/router";
import { toastController } from "@/composables/useToast";
import { useChargerAPI } from "@/api-connectors/useChargerAPI";
import FormCharger from "@/components/Modal/Subcomponents/FormCharger.vue";
import { FormChargerType } from "@/types/RequestTypes";
import { emptyObjects } from "@/types/emptyObjects";

export default defineComponent({
    name: "AssignChargingStation",
    components: { FormCharger },
    props: {
        unassignedStations: {
            type: Array as () => Array<{ value: string, text: string }>,
            required: true
        }
    },
    setup(_, { emit }) {
        const { globalStore } = useGlobalStorage()
        const { addChargingStationRequest } = useChargerAPI()
        const { emptyCharger } = emptyObjects()

        const chargingStation = reactive<FormChargerType>(emptyCharger())

        const resetValues = () => {
            chargingStation.id = '';
            chargingStation.alias = '';
            chargingStation.location = '';
            chargingStation.areaType = '';
        }

        const addChargingStation = async () => {
            const response = await addChargingStationRequest(chargingStation.id ?? '', router.currentRoute.params.companyId ?? '')

            resetValues()

            if (!response) return;

            toastController.toast(`Die Ladestation wurde zugeordnet`, {
                title: 'Ladestation zugeordnet',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })

            emit('update-table')
        }

        const updateChargingStation = (updatedStation: FormChargerType) => {
            Object.assign(chargingStation, updatedStation)
        }

        return {
            chargingStation,
            globalStore,
            addChargingStation,
            updateChargingStation
        }
    }
})
