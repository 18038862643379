





















































































import { computed, defineComponent, onMounted, PropType, reactive, ref, watch } from '@vue/composition-api';
import { FormChargerType } from '@/types/RequestTypes';
import SearchbarTable from '@/components/Table/SearchbarTable.vue';
import { TableChargerManagementWhiteList } from '@/types/tableTypes';
import PaginationRow from '@/components/PaginationRow.vue';
import router from '@/router';
import { useTagAPI } from '@/api-connectors/useTagAPI';
import { toastController } from '@/composables/useToast';
import vueInstance from '@/main';
import { modalController } from '@/composables/useModal';
import { UpdateType } from '@/types/enums';

export default defineComponent({
    name: "EditWhitelistChargingStation",
    components: { PaginationRow, SearchbarTable },
    props: {
        options: {
            type: Array as PropType<string[]>,
            required: true
        },
        selectedCharger: {
            type: Object as PropType<FormChargerType>,
            required: true
        }
    },
    emits: ['update-whitelist'],
    setup(props, { emit }) {
        const { getTagsByCompany } = useTagAPI()
        let items = reactive<TableChargerManagementWhiteList[]>([])

        const alias = props.selectedCharger.alias as string
        const title = `Ladesäulen Whitelist ${alias}`
        const filter = ref('')
        const sortBy = ref('name')
        const isBusy = ref(true)
        const sortDesc = ref(false)
        const isMounting = ref(true)
        const replaceCurrentList = ref(false)

        const currentCompany = computed(() => {
            return router.currentRoute.params.companyId;
        })

        const updateType = computed(() => {
            return replaceCurrentList.value ? UpdateType.FULL : UpdateType.DIFFERENTIAL
        })

        const fields = [
            { key: 'isOnWhitelist', label: 'Whitelist' },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'id', label: 'Tag ID' },
        ]

        const updateRFIDTable = async () => {
            isBusy.value = true
            const tmpItems = await getTagsByCompany(currentCompany.value)
            tmpItems.forEach((item: TableChargerManagementWhiteList) => {
                vueInstance.$set(item, 'isOnWhitelist', false)
                items.push(item)
            })
            if (!items)
                toastController?.toast(`Liste konnte nicht geladen werden!`, {
                    title: 'Fehler',
                    variant: 'danger',
                    autoHideDelay: 3000
                })
            isBusy.value = false
        }

        const checkAlreadySelectedBoxes = () => {
            reset()
            for (const selectedID of props.options) {
                const index = items.findIndex(item => item.id === selectedID)

                if (index < 0) continue;

                items[index].isOnWhitelist = true
            }
        }

        const changeCheckboxValue = (value: boolean, item: TableChargerManagementWhiteList) => {
            if (isMounting.value) return

            const index = items.findIndex(itemEl =>  itemEl.id === item.id)

            if (index < 0) return

            items[index].isOnWhitelist = value
        }

        const emptyWhitelistChanges = () => {
            items.splice(0)
            updateRFIDTable()
            reset()
            emit('update', props.selectedCharger)
            modalController?.hide('edit-whitelist-charging-station')
        }

        const updateWhitelistValues = () => {
            const newItems = items.filter(item => item.isOnWhitelist)

            const newIds = newItems.map(item => {
                return { idTag: item.id }
            })

            emit('update-whitelist', {
                idTags: newIds,
                selectedChargerWhitelist: props.selectedCharger,
                updateType: updateType.value
            })

            emptyWhitelistChanges()
        }

        const reset = () => {
            items.forEach(item => item.isOnWhitelist = false)
            replaceCurrentList.value = false
        }

        onMounted(async () => {
            await updateRFIDTable()
            isMounting.value = false
        })

        watch(() => props.options.length, checkAlreadySelectedBoxes)

        return {
            alias,
            title,
            fields,
            filter,
            sortBy,
            sortDesc,
            isBusy,
            items,
            updateType,
            replaceCurrentList,
            updateRFIDTable,
            changeCheckboxValue,
            updateWhitelistValues,
            emptyWhitelistChanges,
        }
    }
})
