


















































































import { computed, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { modalController, useModal } from "@/composables/useModal";
import { toastController } from "@/composables/useToast";
import { useTable } from "@/composables/useTable";
import AddChargingStation from "@/components/Modal/AssignChargingStation.vue";
import { TableChargerManagement } from "@/types/tableTypes";
import PaginationRow from "@/components/PaginationRow.vue";
import { useChargerAPI } from "@/api-connectors/useChargerAPI";
import router from "@/router";
import EditChargingStation from "@/components/Modal/EditChargingStation.vue";
import { emptyObjects } from "@/types/emptyObjects";
import { FormChargerType } from "@/types/RequestTypes";
import SearchbarTable from "@/components/Table/SearchbarTable.vue";
import EditWhitelistChargingStation from "@/components/Modal/EditWhitelistChargingStation.vue";
import { UpdateType } from '@/types/enums';

export default defineComponent({
    name: "ManagementChargingStation",
    components: {
        EditWhitelistChargingStation,
        SearchbarTable,
        EditChargingStation,
        PaginationRow,
        AddChargingStation
    },
    setup() {
        const {
            getUnassignedChargingStation,
            removeChargingStationRequest,
            getLocalAuthList,
            updateLocalAuthList
        } = useChargerAPI()
        const { createMsgBoxConfirm } = useModal()
        const {
            updateTable,
            pageOptions
        } = useTable()
        const { emptyCharger } = emptyObjects()

        const items = reactive<TableChargerManagement[]>([])
        const unassignedStations = reactive<{ value: string, text: string }[]>([])
        const selectedCharger = reactive<FormChargerType>(emptyCharger())
        const optionsChargerPointWhitelist = reactive<string[]>([])

        const amountRows = ref(0)
        const perPage = ref(10)
        const currentPage = ref(1)
        const isBusy = ref(true)
        const filter = ref('')
        const sortBy = ref('name')
        const sortDesc = ref(false)

        const fields = [
            { key: 'name', label: 'Name', sortable: true },
            { key: 'alias', label: 'Alias', sortable: true },
            { key: 'meterId', label: 'Zählernummer' },
            { key: 'costFactor.areaType', label: 'Kostenfaktor', sortable: true },
            { key: 'edit', label: 'Whitelist verwalten' },
            { key: 'id', label: '' },
        ]

        const currentCompany = computed(() => {
            return router.currentRoute.params.companyId;
        })

        const updateChargingStationTable = async (page?: number) => {
            isBusy.value = true
            if (!page) page = currentPage.value;
            const offset = (page - 1) * perPage.value
            await updateTable({
                routePath: 'charger/company/' + currentCompany.value,
                targetList: items,
                targetMaxCount: amountRows,
                offset: offset,
                perPage: perPage.value,
                filter: filter.value,
            })
            isBusy.value = false
        }

        interface WhitelistObj {
            idTags: { idTag: string }[];
            selectedChargerWhitelist: FormChargerType;
            updateType: UpdateType;
        }

        const updateChargingPointWhitelist = async (whitelistObj: WhitelistObj) => {
            if (!whitelistObj.selectedChargerWhitelist.id) return

            await updateLocalAuthList(whitelistObj.selectedChargerWhitelist.id, whitelistObj.idTags, whitelistObj.updateType)
        }

        const getUnassignedChargers = async (): Promise<Boolean> => {
            const response = await getUnassignedChargingStation()

            if (!response) return false;

            unassignedStations.splice(0)
            unassignedStations.push({ value: '', text: 'Bitte wählen' });
            for (const station of response.data) {
                unassignedStations.push({ value: station.id, text: station.name })
            }
            return true;
        }

        const showAssignChargingStation = async () => {
            if (await getUnassignedChargers()) modalController.show('assign-charging-station')
        }

        const resetFilter = () => {
            filter.value = ''
            updateChargingStationTable()
        }

        const assignChargerValues = (clickedCharger: TableChargerManagement) => {
            selectedCharger.id = clickedCharger.id;
            selectedCharger.alias = clickedCharger.alias;
            selectedCharger.location = clickedCharger.location
            selectedCharger.group = clickedCharger.group
            selectedCharger.meterId = clickedCharger.meterId
            selectedCharger.areaType = clickedCharger.costFactor?.areaType ?? (clickedCharger.areaType ?? '')
        }

        const showEditChargingStation = (clickedCharger: TableChargerManagement) => {
            // if (await getUnassignedChargers()) {
            assignChargerValues(clickedCharger)
            modalController.show('edit-charging-station')
            // }
        }

        const removeChargingStation = async (chargerId: string) => {
            const response = await removeChargingStationRequest(chargerId)

            if (!response) return;

            await updateChargingStationTable()
            toastController.toast(`Die Zuordnung wurde entfernt`, {
                title: 'Ladestation entfernt',
                autoHideDelay: 3000,
                variant: 'success',
                solid: true
            })
        }

        const showEditWhiteList = async (clickedCharger: TableChargerManagement) => {
            editWhitelist(clickedCharger)

            modalController.show('edit-whitelist-charging-station')
        }

        const editWhitelist = async (clickedCharger: TableChargerManagement) => {
            assignChargerValues(clickedCharger)
            optionsChargerPointWhitelist.splice(0)

            const localAuthList = await getLocalAuthList(clickedCharger.id)

            if (localAuthList)
                for (const charger of localAuthList.entries) {
                    optionsChargerPointWhitelist.push(charger.rfidTag.id)
                }
            else
                toastController.toast('Fehler beim Laden der Whitelist', {
                    title: 'Whitelist konnte nicht geladen werden.',
                    autoHideDelay: 3000,
                    variant: 'danger',
                    solid: true
                })
        }

        const checkRemoveChargingStation = (chargerId: string) => {
            createMsgBoxConfirm({
                header: 'Ladestation entfernen',
                contentText: 'Möchten Sie die Zuordnung entfernen?',
                okHandler: () => removeChargingStation(chargerId)
            })
        }

        onMounted(() => updateChargingStationTable(1))

        return {
            items,
            fields,
            pageOptions,
            perPage,
            currentPage,
            amountRows,
            isBusy,
            unassignedStations,
            selectedCharger,
            filter,
            sortBy,
            sortDesc,
            optionsChargerPointWhitelist,
            resetFilter,
            showAssignChargingStation,
            checkRemoveChargingStation,
            updateChargingStationTable,
            showEditChargingStation,
            editWhitelist,
            showEditWhiteList,
            updateChargingPointWhitelist,
        }
    }
})
