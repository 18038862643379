





















































import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import FormInput from "@/components/FormFields/FormInput.vue";
import { CostFactor } from "@/types/apiEntities";
import useGlobalStorage from "@/composables/useGlobalStorage";
import { FormChargerType } from "@/types/RequestTypes";
import { useCostFactorAPI } from "@/api-connectors/useCostFactorAPI";

export default defineComponent({
    name: "FormCharger",
    components: { FormInput },
    props: {
        // selectedStation: {
        //     type: String
        // },
        unassignedStations: {
            type: Array as () => Array<{ value: string, text: string }>,
        },
        chargingStation: {
            type: Object as PropType<FormChargerType>,
            required: true
        },
        edit: Boolean
    },
    setup(props) {
        const { globalStore } = useGlobalStorage()
        const { getAllCostFactors } = useCostFactorAPI()

        let updatedStation = reactive<FormChargerType>(props.chargingStation)
        const costFactors = reactive<{ value: string; text: string; disabled?: boolean }[]>([])

        const getCostFactors = async () => {
            const costFactorList: CostFactor[] = (await getAllCostFactors())?.data

            costFactors.splice(0)
            costFactors.push({ value: '', text: 'Bitte wählen', disabled: true })
            if (!costFactorList) return;

            for (const costFactor of costFactorList) {
                const tempObject = { value: costFactor.areaType, text: costFactor.areaType }
                costFactors.push(tempObject)
            }
        }

        onMounted(() => getCostFactors())

        return {
            updatedStation,
            globalStore,
            costFactors
        }
    }
})
