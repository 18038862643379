import { useAPI } from "@/composables/useAPI";
import { RfidTag } from "@/types/apiEntities";
import { toastController } from "@/composables/useToast";

export function useTagAPI() {
    const { api } = useAPI()

    const deleteTagRequest = async (tagId: string) => {
        return await api.delete(`rfidTag/${encodeURIComponent(tagId)}/remove`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const setTagRequest = async (tagId: string, status: boolean) => {
        return await api.put(`rfidTag/${encodeURIComponent(tagId)}/active/${!status}`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const addTagRequest = async (newTag: RfidTag) => {
        return await api.post('rfidTag/add', newTag)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const editTagRequest = async (originalID: string, editedTag: RfidTag) => {
        return await api.put(`rfidTag/update/${originalID}`, editedTag)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const getTagRequest = async (tagId: string) => {
        return await api.get(`rfidTag/${tagId}`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const getTagsByCompany = async(companyId: string) => {
        const response = await api.get(`rfidTag/byCompany/${companyId}`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
        if (response)
            return response.data.list
        else
            return undefined
    }

    return {
        addTagRequest,
        deleteTagRequest,
        setTagRequest,
        editTagRequest,
        getTagRequest,
        getTagsByCompany
    }
}